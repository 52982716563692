import type {
	LinksFunction,
	LoaderFunction,
	MetaFunction,
} from '@remix-run/node';

import { useLoaderData } from '@remix-run/react';

import divider from '~/components/atoms/Divider/index.tsx';

import stylesUrl from '~/styles/index.css?url';

export const links: LinksFunction = () => [
	{
		rel: 'stylesheet',
		href: stylesUrl,
	},
];

export const meta: MetaFunction = () => [
	{
		title: 'GList | Create and share group lists',
	},
];

export const loader: LoaderFunction = async () => {
	const theDivider = divider();
	return {
		divider: theDivider,
	};
};

export default function Index(): React.ReactNode {
	const { divider } = useLoaderData<typeof loader>();
	return (
		<main id="main">
			<section className="banner">
				<header className="banner--heading">
					<div className="banner--content">
						<h1>
							All of your lists, <br />
							for anything, <br />
							in one place
						</h1>
					</div>
				</header>
				<div className="banner--image">
					<picture className="banner--picture">
						<source
							srcSet="/img/home/banner.avif"
							type="image/avif"
							media="(min-width: 768px)"
						/>
						<source
							srcSet="/img/home/banner.jpg"
							type="image/jpg"
							media="(min-width: 768px)"
						/>
						<img
							src="/img/home/banner-mob.jpg"
							alt=""
							width="1270"
							height="845"
						/>
					</picture>
				</div>
			</section>
			<div
				className="divider"
				role="img"
				aria-label="Mountain-scape divider"
				aria-hidden="true"
				dangerouslySetInnerHTML={{ __html: divider }}
			/>
			<section className="section light">
				<div className="container content">
					<div className="content--left">
						<h2>Create lists for anything</h2>
						<p>
							Whether you need a to-do list, shopping list, bucket
							list, holiday list or any kind of list, we've got
							you covered.
						</p>
					</div>
					<div className="content--right">
						<img
							src="/img/checklist.svg"
							alt=""
							width="369"
							height="250"
						/>
					</div>
				</div>
			</section>
			<div
				className="divider flipped"
				role="img"
				aria-label="Mountain-scape divider"
				aria-hidden="true"
				dangerouslySetInnerHTML={{ __html: divider }}
			/>
			<section className="section">
				<div className="container content">
					<div className="content--left">
						<img
							src="/img/groups.svg"
							alt=""
							loading="lazy"
							width="327"
							height="250"
						/>
					</div>
					<div className="content--right">
						<h2>Separate your lists into groups</h2>
						<p>
							Need a separate space to put your gaming
							requirements and your shopping list? We've got you
							covered there, too.
						</p>
					</div>
				</div>
			</section>
			<div
				className="divider"
				role="img"
				aria-label="Mountain-scape divider"
				aria-hidden="true"
				dangerouslySetInnerHTML={{ __html: divider }}
			/>
			<section className="section light">
				<div className="container content">
					<div className="content--left">
						<h2>Invite your friends and family</h2>
						<p>
							If you need to collaborate on lists you can easily
							invite friends and family as lists and allow them to
							add and edit list items.
						</p>
					</div>
					<div className="content--right">
						<img
							src="/img/collab.svg"
							alt=""
							loading="lazy"
							width="272"
							height="250"
						/>
					</div>
				</div>
			</section>
			<div
				className="divider flipped"
				role="img"
				aria-label="Mountain-scape divider"
				aria-hidden="true"
				dangerouslySetInnerHTML={{ __html: divider }}
			/>
			<section className="section">
				<div className="container content">
					<div className="content--left">
						<img
							src="/img/custom.svg"
							alt=""
							loading="lazy"
							width="281"
							height="250"
						/>
					</div>
					<div className="content--right">
						<h2>Customise groups and lists</h2>
						<p>
							If you want to personalise your experience you can
							choose how your groups and lists look with various
							customisation options such as colours and layouts.
						</p>
					</div>
				</div>
			</section>
			<div
				className="divider"
				role="img"
				aria-label="Mountain-scape divider"
				aria-hidden="true"
				dangerouslySetInnerHTML={{ __html: divider }}
			/>
			<section className="section light">
				<div className="container content">
					<div className="content--left">
						<h2>Control who can see what</h2>
						<p>
							As you add list items you can decide who else can
							see them. Create specific items for one person or
							hide list items that you don't want others to see.
						</p>
					</div>
					<div className="content--right">
						<img
							src="/img/control.svg"
							alt=""
							loading="lazy"
							width="293"
							height="250"
						/>
					</div>
				</div>
			</section>
			<div
				className="divider flipped"
				role="img"
				aria-label="Mountain-scape divider"
				aria-hidden="true"
				dangerouslySetInnerHTML={{ __html: divider }}
			/>
			<section className="section">
				<div className="container content">
					<div className="content--left">
						<img
							src="/img/budget.svg"
							alt=""
							loading="lazy"
							width="293"
							height="250"
						/>
					</div>
					<div className="content--right">
						<h2>Manage budgets</h2>
						<p>
							Add prices and links to list items and set budgets
							per list or group to manage spend.
						</p>
					</div>
				</div>
			</section>
		</main>
	);
}
